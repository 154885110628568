<template>
  <div class="col-start-1 col-end-3 overflow-y-auto">
    <div class="col-start-1 col-end-3 my-6 flex justify-around">
      <el-tooltip
        :open-delay="700"
        effect="dark"
        :content="$t('objects_tabs.extra.motohours')"
        placement="right"
        :offset="0"
        :visible-arrow="false"
      >
        <div class="relative">
          <svg
            class="cursor-pointer transition-colors ease-in-out duration-200"
            :class="
              extraTab === 'cf_motohours'
                ? 'fill-fillTrash'
                : 'fill-annotationColor'
            "
            @click="extraTab = 'cf_motohours'"
            width="29"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.74 4.239s-.007-.007-.014-.007A14.377 14.377 0 0014.493 0C10.631 0 6.99 1.5 4.253 4.232c0 0-.006.007-.013.007-.007 0-.007.006-.007.013A14.343 14.343 0 000 14.465c0 1.272.169 2.523.492 3.728v.006c.007.02.007.04.014.06 0 .014.013.021.013.028a.825.825 0 00.128.269l.007.007c.054.08.121.148.195.208.014.014.027.02.04.034.075.054.163.1.25.134.02.007.034.014.054.014.095.027.196.047.297.047h26.02c.101 0 .202-.02.297-.047.02-.007.034-.007.054-.014a.897.897 0 00.25-.134c.013-.007.026-.02.04-.034a.957.957 0 00.195-.208l.007-.007c.054-.08.094-.175.128-.27 0-.013.013-.02.013-.026.007-.02.007-.04.014-.06v-.007c.323-1.205.492-2.456.492-3.728 0-3.855-1.503-7.488-4.24-10.22-.014 0-.014-.006-.02-.006zM14.73 15.374a.339.339 0 01-.237.1.329.329 0 01-.236-.1.337.337 0 01-.1-.236c0-.047.013-.155.1-.235a.339.339 0 01.236-.101c.047 0 .155.013.236.1a.337.337 0 01.101.236.327.327 0 01-.1.236zm11.223.1h.957c-.04.512-.1 1.016-.202 1.508H15.943c.074-.061.148-.115.215-.182a2.344 2.344 0 00.695-1.669c0-.632-.243-1.217-.688-1.662a2.392 2.392 0 00-.654-.444V7.731c0-.559-.451-1.01-1.011-1.01-.56 0-1.011.451-1.011 1.01v5.294a2.047 2.047 0 00-.654.444 2.344 2.344 0 00-.694 1.67c0 .632.242 1.217.687 1.66.068.068.142.122.216.183H2.278a12.284 12.284 0 01-.202-1.507h.957a1.009 1.009 0 100-2.019H2.07a12.324 12.324 0 012.925-7.044l.681.68c.195.194.458.295.715.295.256 0 .519-.1.714-.296a1.002 1.002 0 000-1.426l-.68-.68a12.373 12.373 0 017.057-2.92v.963c0 .558.452 1.009 1.011 1.009.56 0 1.011-.45 1.011-1.01v-.961a12.328 12.328 0 017.058 2.92l-.68.679a1.002 1.002 0 000 1.426c.195.195.458.296.714.296.256 0 .52-.1.715-.296l.68-.68a12.324 12.324 0 012.926 7.045h-.964a1.009 1.009 0 100 2.019z"
            ></path>
          </svg>
          <skif-badge
            isObject
            :value="filterDataExtraMotohours.length"
          ></skif-badge>
        </div>
      </el-tooltip>
      <el-tooltip
        :open-delay="700"
        effect="dark"
        :content="$t('objects_tabs.extra.trips')"
        placement="right"
        :offset="0"
        :visible-arrow="false"
      >
        <div class="relative">
          <svg
            class="cursor-pointer transition-colors ease-in-out duration-200"
            :class="
              extraTab === 'cf_trips'
                ? 'fill-fillTrash'
                : 'fill-annotationColor'
            "
            @click="extraTab = 'cf_trips'"
            width="29"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.94 4.667c-2.23 0-4.061 1.818-4.061 4.03 0 2.182 1.862 5.06 2.992 6.606.244.333.64.545 1.068.545.428 0 .824-.212 1.069-.545C27.138 13.788 29 10.909 29 8.697c0-2.212-1.832-4.03-4.06-4.03zm0 9.454c-1.588-2.182-2.504-4.182-2.504-5.394 0-1.364 1.13-2.485 2.503-2.485 1.374 0 2.503 1.121 2.503 2.485 0 1.212-.946 3.212-2.503 5.394zM24.942 17.424H9.708c-.946 0-1.74-.788-1.74-1.727 0-.94.794-1.727 1.74-1.727h3.572c1.832 0 3.297-1.485 3.297-3.273s-1.465-3.303-3.267-3.303h-.61c.458-.242.824-.727.885-1.273h.427a.698.698 0 00.703-.636l.152-1.727c.03-.394-.244-.728-.61-.758l-3.297-.424-1.832-2A1.81 1.81 0 007.815 0H4.55c-.55 0-1.038.273-1.313.758L2.168 2.606l-1.16.06a.714.714 0 00-.672.758l.152 2.06a.785.785 0 00.764.668h.488c.061.545.397 1 .885 1.272H.794A.785.785 0 000 8.212C0 8.636.366 9 .794 9H13.31c.947 0 1.74.788 1.74 1.727 0 .94-.763 1.727-1.74 1.727H9.708c-1.832 0-3.297 1.485-3.297 3.273C6.411 17.546 7.907 19 9.708 19h15.203a.785.785 0 00.794-.788c0-.454-.336-.788-.763-.788zM11.937 5.091c.488 0 .885.394.885.879a.883.883 0 01-.885.878.883.883 0 01-.886-.878c0-.515.397-.88.886-.88zM6.625.939h1.19c.244 0 .458.091.61.273l1.222 1.333H6.594L6.624.94zm-2.565.273c.092-.182.275-.273.489-.273h1.13v1.606H3.296l.763-1.333zm-.702 3.879c.489 0 .885.394.885.879a.883.883 0 01-.885.878.883.883 0 01-.885-.878c.03-.515.397-.88.885-.88zm1.649 1.06h5.281c.061.546.397 1 .885 1.273H4.121c.489-.272.825-.727.886-1.272z"
            ></path>
            <path
              d="M24.908 10.03c.742 0 1.343-.597 1.343-1.333s-.601-1.333-1.343-1.333-1.343.597-1.343 1.333.601 1.333 1.343 1.333z"
            ></path>
          </svg>
          <skif-badge
            isObject
            :value="filterDataExtraTrips.length"
          ></skif-badge>
        </div>
      </el-tooltip>
      <el-tooltip
        :open-delay="700"
        effect="dark"
        :content="$t('objects_tabs.extra.fuel')"
        placement="right"
        :offset="0"
        :visible-arrow="false"
      >
        <div class="relative">
          <svg
            class="cursor-pointer transition-colors ease-in-out duration-200"
            :class="
              extraTab === 'cf_fuel' ? 'fill-fillTrash' : 'fill-annotationColor'
            "
            @click="extraTab = 'cf_fuel'"
            width="15"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.365 5.899l.635-.53C14.044 2.672 12.22.648 9.996 0v1.322s.2.11.298.17c1.465.907 2.48 2.641 2.642 4.575l.527 8.08v.035c0 .658-.457 1.194-1.02 1.194-.562 0-1.02-.536-1.02-1.194v-4.62c0-1.459-.936-2.665-2.141-2.842V1.797c0-.92-.643-1.672-1.428-1.672H2.142C1.357.125.714.877.714 1.797v15.53H0V19H9.996v-1.672H9.28V8.429c.413.153.715.6.715 1.133v4.62c0 1.58 1.098 2.867 2.447 2.867 1.35 0 2.448-1.286 2.448-2.867l-.001-.108-.525-8.175zM2.142 1.797h5.712v4.868H2.142V1.797z"
            ></path>
          </svg>
          <skif-badge isObject :value="filterDataExtraFuel.length"></skif-badge>
        </div>
      </el-tooltip>
      <el-tooltip
        :open-delay="700"
        effect="dark"
        :content="$t('objects_tabs.extra.etc')"
        placement="right"
        :offset="0"
        :visible-arrow="false"
      >
        <div class="relative">
          <svg
            class="cursor-pointer transition-colors ease-in-out duration-200"
            :class="
              extraTab === 'cf_etc' ? 'fill-fillTrash' : 'fill-annotationColor'
            "
            @click="extraTab = 'cf_etc'"
            @mouseover="etcLabel = true"
            @mouseout="etcLabel = false"
            width="19"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.967 8.867H1.9c-1.048 0-1.9-.853-1.9-1.9V1.9C0 .852.852 0 1.9 0h5.067c1.047 0 1.9.852 1.9 1.9v5.067c0 1.047-.853 1.9-1.9 1.9zM1.9 1.267a.634.634 0 00-.633.633v5.067c0 .35.283.633.633.633h5.067c.35 0 .633-.284.633-.633V1.9a.634.634 0 00-.633-.633H1.9zM17.1 8.867h-5.067c-1.047 0-1.9-.853-1.9-1.9V1.9c0-1.048.853-1.9 1.9-1.9H17.1c1.047 0 1.9.852 1.9 1.9v5.067c0 1.047-.852 1.9-1.9 1.9zm-5.067-7.6a.634.634 0 00-.633.633v5.067c0 .35.284.633.633.633H17.1c.35 0 .633-.284.633-.633V1.9a.634.634 0 00-.633-.633h-5.067zM17.1 19h-5.067c-1.047 0-1.9-.852-1.9-1.9v-5.067c0-1.047.853-1.9 1.9-1.9H17.1c1.047 0 1.9.853 1.9 1.9V17.1c0 1.048-.852 1.9-1.9 1.9zm-5.067-7.6a.634.634 0 00-.633.633V17.1c0 .35.284.633.633.633H17.1c.35 0 .633-.283.633-.633v-5.067a.634.634 0 00-.633-.633h-5.067zM6.967 19H1.9C.852 19 0 18.148 0 17.1v-5.067c0-1.047.852-1.9 1.9-1.9h5.067c1.047 0 1.9.853 1.9 1.9V17.1c0 1.048-.853 1.9-1.9 1.9zM1.9 11.4a.634.634 0 00-.633.633V17.1c0 .35.283.633.633.633h5.067c.35 0 .633-.283.633-.633v-5.067a.634.634 0 00-.633-.633H1.9z"
            ></path>
          </svg>

          <skif-badge isObject :value="filterDataExtraEtc.length"></skif-badge>
        </div>
      </el-tooltip>
      <el-tooltip
        :open-delay="700"
        effect="dark"
        :content="$t('objects_tabs.extra.custom')"
        placement="right"
        :offset="0"
        :visible-arrow="false"
      >
        <div class="relative">
          <svg
            class="cursor-pointer transition-colors ease-in-out duration-200"
            :class="
              extraTab === 'cf_custom'
                ? 'fill-fillTrash'
                : 'fill-annotationColor'
            "
            @click="extraTab = 'cf_custom'"
            @mouseover="customLabel = true"
            @mouseout="customLabel = false"
            width="19"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 4.75A4.75 4.75 0 014.75 0h3.563a1.187 1.187 0 110 2.375H4.75A2.375 2.375 0 002.375 4.75v9.5a2.375 2.375 0 002.375 2.375h9.5a2.375 2.375 0 002.375-2.375v-3.563a1.188 1.188 0 012.375 0v3.563A4.75 4.75 0 0114.25 19h-9.5A4.75 4.75 0 010 14.25v-9.5zM16.973.348a1.188 1.188 0 011.68 1.68L10.34 10.34a1.188 1.188 0 01-1.68-1.68L16.973.348z"
            ></path>
          </svg>

          <skif-badge
            isObject
            :value="filterDataExtraCustom.length"
          ></skif-badge>
        </div>
      </el-tooltip>
    </div>
    <div class="col-start-1 col-end-3 mb-4">
      <table class="w-full table-fixed">
        <thead class="text-left">
          <tr class="border-b border-reallylightblue">
            <th class="bg-white top-0 sticky z-10" style="width: 50%">
              <span
                class="
                  text-headerText text-sm
                  font-SourceSansPro
                  leading-10
                  font-semibold
                "
                >{{ $t('objects_trailer_moreFields') }}</span
              >
            </th>
            <th
              class="border-b border-reallylightblue bg-white top-0 sticky z-10"
              style="width: 30px"
            ></th>
            <th class="bg-white top-0 sticky z-10"></th>
            <th class="text-right w-6 top-0 sticky z-10 bg-white">
              <svg
                class="
                  cursor-pointer
                  fill-annotationColor
                  hover_fill-fillTrash
                  transition-all
                  ease-in-out
                  duration-200
                  transform
                "
                :class="isNewExtra ? 'rotate-45' : 'rotate-0'"
                @click="createExtra(extraTab)"
                v-if="extraTab === 'cf_custom'"
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z"
                ></path>
              </svg>
            </th>
          </tr>
          <tr class="border-b border-reallylightblue" v-if="isNewExtra">
            <th :colspan="3">
              <div class="flex items-end mt-3 mb-6">
                <div class="flex flex-col unitDriverModal w-2/3">
                  <div class="flex flex-col relative">
                    <span
                      class="
                        text-annotationColor
                        font-SourceSansPro
                        text-sm
                        font-semibold
                      "
                      >{{ $t('name') }}</span
                    >
                    <el-input
                      class="mt-3"
                      type="text"
                      v-model="newExtra.key"
                      :class="{ 'input-error': isExtraNameMissing }"
                    ></el-input>
                    <transition name="el-zoom-in-top">
                      <div
                        class="text-notify text-xs top-full left-0 absolute"
                        v-if="isExtraNameMissing"
                      >
                        {{ $t('objects.name') }}
                      </div>
                    </transition>
                  </div>
                  <div class="flex flex-col relative">
                    <span
                      class="
                        mt-6
                        text-annotationColor
                        font-SourceSansPro
                        text-sm
                        font-semibold
                      "
                      >{{ $t('value') }}</span
                    >
                    <el-input
                      class="mt-3"
                      type="text"
                      v-model="newExtra.value"
                      :class="{ 'input-error': isExtraValueMissing }"
                    ></el-input>
                    <transition name="el-zoom-in-top">
                      <div
                        class="text-notify text-xs top-full left-0 absolute"
                        v-if="isExtraValueMissing"
                      >
                        {{ $t('objects_tabs.sensors.enterValue') }}
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="flex justify-end w-1/3">
                  <skif-button width="28" @click="addExtraToUnit(extraTab)">{{
                    $t('btn.save')
                  }}</skif-button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(extra, index) in filterDataExtra" :key="index">
            <td class="truncate pr-2 text-darkblue">
              <el-input
                v-if="extraTab === 'cf_custom'"
                :disabled="disabledRole && role !== 'EDITOR'"
                v-model="extra.name"
                type="text"
              ></el-input>
              <el-tooltip
                :open-delay="700"
                v-else
                effect="dark"
                :content="extra.name"
                placement="bottom"
                :offset="0"
                :visible-arrow="false"
                ><span class="font-bold leading-10 text-sm">{{
                  extra.name
                }}</span></el-tooltip
              >
            </td>
            <td class="cursor-pointer" style="width: 50px">
              <el-tooltip
                :open-delay="700"
                effect="dark"
                :content="$t('objects.showmonitoring')"
                placement="bottom"
                :offset="0"
                :visible-arrow="false"
              >
                <svg
                  @click="showInMonitoring(extra)"
                  v-if="extra.show_in_monitoring"
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 12c1.415-4.097 4.91-7 9-7s7.584 2.903 9 7c-1.416 4.097-4.91 7-9 7s-7.585-2.903-9-7zm13 0a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
                    fill="#5477A9"
                  ></path>
                </svg>
                <svg
                  @click="showInMonitoring(extra)"
                  v-else
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.036 15.864l-2.444-2.444-.012-.011-2.445-2.445a4 4 0 004.9 4.9zM5.28 8.107L4 6.82l1.41-1.41 13.18 13.18L17.18 20l-1.732-1.723A8.588 8.588 0 0112 19c-4.09 0-7.585-2.903-9-7a11.297 11.297 0 012.279-3.893zm13.442 7.786l-2.857-2.857a4.005 4.005 0 00-4.9-4.9L8.552 5.722A8.587 8.587 0 0112 5c4.09 0 7.584 2.903 9 7a11.298 11.298 0 01-2.279 3.893z"
                    fill="#5477A9"
                  ></path>
                </svg>
              </el-tooltip>
            </td>
            <td style="height: 60px">
              <div class="relative">
                <el-input
                  v-if='extraTab === "cf_custom" || extraTab === "cf_etc" || customFields.find((cf) =&gt; cf.key === extra.key).type === "text"'
                  :disabled="disabledRole && role !== 'EDITOR'"
                  v-model="extra.value"
                  :type="extra.key === 'hours_rate' ? 'number' : 'text'"
                  @input="handlePattern(extra)"
                  :class="{ 'input-error': extra.isRegex === false }"
                ></el-input>
                <el-select
                  v-else
                  :disabled="disabledRole && role !== 'EDITOR'"
                  v-model="extra.value"
                >
                  <el-option
                    v-for="item in customFields.find((cf) =&gt; cf.key === extra.key).values"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
                <transition name="el-zoom-in-top">
                  <div
                    class="text-notify text-xs top-full left-0 top-0 absolute"
                    v-if="extra.isRegex === false"
                  >
                    неверное значение
                  </div>
                </transition>
              </div>
            </td>
            <td class="flex items-center justify-end">
              <svg
                class="
                  cursor-pointer
                  fill-annotationColor
                  hover_fill-fillTrash
                  transition-colors
                  ease-in-out
                  duration-200
                "
                width="12"
                height="16"
                fill="none"
                v-if="extraTab === 'cf_custom'"
                xmlns="http://www.w3.org/2000/svg"
                @click="(event) =&gt; { event.stopPropagation(); deleteExtra(extra.key); }"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z"
                ></path>
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import extra from '../mixins/extra'
export default {
  mixins: [extra],
  props: [
    'role',
    'customFields',
    'unit',
    'filterStringExtra',
    'notRole',
    'disabledRole',
  ],
  data() {
    return {}
  },

  methods: {
    showInMonitoring(extra) {
      extra.show_in_monitoring = !extra.show_in_monitoring
    },
    handlePattern(element) {
      let currentField = this.customFields.filter((el) => {
        return el.key === element.key
      })
      let currentPattern = new RegExp(currentField[0].pattern)
      this.isRegexValid = currentPattern.test(element.value)
      element.isRegex = this.isRegexValid
    },
  },
}
</script>

<style></style>
